import { FC, Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./app.routes";
import ErrorBoundary from "components/error-boundary";
import Footer from "components/footer";
import Header from "components/header";
import { configureChains, createClient, WagmiConfig, mainnet } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
// import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import store from "store";
import ScrollToTop from "utils/scrollToTop";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import ReactGA from "react-ga";
const { WALLETCONNECT_PROJECT_ID } = process.env;

window.Buffer = window.Buffer || require("buffer").Buffer;

const { chains, provider } = configureChains([mainnet], [publicProvider()]);

// const payPalClientId = process.env.PAYPAL_CLIENT_ID as string;

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId:
          WALLETCONNECT_PROJECT_ID || "465b90db5de88e8b2888ed1aee667d2d",
      },
    }),
  ],
  provider,
});

ReactGA.initialize("G-X67YCQHDQQ");

const App: FC = () => {
  return (
    // <PayPalScriptProvider
    //   options={{
    //     "client-id": payPalClientId,
    //     currency: "USD",
    //   }}
    // >
    <Provider store={store}>
      <WagmiConfig client={client}>
        <Router>
          <ScrollToTop />
          <ErrorBoundary>
            <Suspense>
              <Header />
              <main className="main-container">
                <AppRoutes />
              </main>
              <Footer />
            </Suspense>
          </ErrorBoundary>
        </Router>
      </WagmiConfig>
    </Provider>
    // </PayPalScriptProvider>
  );
};

export default App;
